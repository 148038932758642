import {
  CartItem, Product as CCProduct, useAddCartItemMutation, transformProductToCartItemInput
} from '@pizza-hut-us-development/client-core';
import { SerializedError } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import triggerMutationWrapper from '@/clientCore/helper/triggerMutationWrapper';
import { orderSelectors } from '@/clientCore/redux/selectors/orderSelectors';
import { addSortedItemPointer } from '@/clientCore/redux/rail/CartRailSlice';

export type AddToCart = (
  product: CCProduct,
  quantity: number,
  isYumEcomm: boolean,
  onSuccess?: (item: CartItem) => void,
  onError?: (error: SerializedError) => void,
) => Promise<void>;

export function useAddToCart() {
  const dispatch = useDispatch();
  const [addItemToCart, { isLoading }] = useAddCartItemMutation();
  const cartId = useSelector(orderSelectors.cartId);

  const addToCart: AddToCart = async function (product, quantity, isYumEcomm, onSuccess?, onError?) {
    const payload = transformProductToCartItemInput(product, isYumEcomm);
    await triggerMutationWrapper(addItemToCart({ cartId, item: { ...payload, quantity } }), {
      onSuccess: (response) => {
        const cartItem = response?.items.find((i) => i.id === product.id);
        if (!cartItem) {
          return;
        }
        dispatch(
          addSortedItemPointer({
            cartItemId: cartItem.cartItemId,
            itemId: cartItem.id
          })
        );
        if (onSuccess) {
          onSuccess(cartItem);
        }
      },
      onError: (e) => {
        if (onError) onError(e);
      }
    });
  };

  return {
    addToCart,
    isLoading
  };
}

export default useAddToCart;
