import React from 'react';
import Typography from '@material-ui/core/Typography';
import useGetSizeCrustDetails from './hooks/useGetSizeCrustDetails';
import { TProductDetails } from '@/common/ProductDetails/types';
import formattedPrice from '@/common/formattedPrice';

const ProductDetails = ({ price, showPrefix, product } : TProductDetails): JSX.Element => {
  const sizeCrustSelections = useGetSizeCrustDetails(showPrefix, product);
  return (
    <>
      <Typography data-testid="product-details-size">
        {sizeCrustSelections}
      </Typography>
      <Typography data-testid="product-details-price">
        {formattedPrice(price)}
      </Typography>
    </>
  );
};

export default ProductDetails;
