import { makeStyles, Theme } from '@material-ui/core';
import fontStyles from '@/common/fontStyles';
import { smallMobileStartBreakpoint } from '@/materialUi/theme';
import colors from '@/common/colors';

interface Props {
  stackCTA: boolean;
}

export const useMenuTileStyles = makeStyles<Theme, Props>((theme) => ({
  grid: (props) => ({
    ...(props.stackCTA && {
      paddingTop: '16px',
      gridTemplateAreas: `
        "image info"
        "cta cta"
        "nutrition nutrition"
      `
    }),
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      gridTemplateAreas: `
        "image info"
        "cta cta"
        "nutrition nutrition"
      `
    },
    ...(props.stackCTA && {
      '&:first-child': {
        marginTop: 0
      }
    })
  }),
  cta: (props) => ({
    flexWrap: 'wrap',
    gap: '24px',
    padding: '0 24px',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    marginLeft: 'auto',
    ...(props.stackCTA && {
      gridColumn: '1 / 3',
      justifyContent: 'space-between',
      marginTop: '20px',
      marginLeft: '0'
    }),
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      gridColumn: '1 / 3',
      marginLeft: '0',
      marginTop: '20px',
      padding: '0 16px',
      justifyContent: 'flex-end'
    }
  }),
  ctaButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '16rem',
    marginLeft: 'auto',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      flexGrow: 1
    }
  },
  ctaLinkWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
      height: 'auto'
    }
  },
  ctaLink: {
    ...fontStyles.commonComponentSelectedState,
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontWeight: 'bold',
    color: colors.blue,
    '&:focus': {
      padding: 0
    }
  },
  badges: {
    display: 'flex',
    gap: '5px'
  },
  imageClickable: {
    cursor: 'pointer'
  }
}));
