import { Modifier, Product } from '@pizza-hut-us-development/client-core';
import { CRUST_SUBTYPE, SIZE_SUBTYPE } from '@/clientCore/menu/utils/pizzaNutrition';

const useGetSizeCrustDetails = (showPrefix: boolean, product: Product): string => {
  if (!showPrefix) return '';

  const getSizeCrust = (items: Modifier[], subtypes: string[]): string => items
    .reduce((acc: string[], item) => {
      if (subtypes.includes(item?.subtype ?? '') && item?.selected) {
        acc.push(item.name);
      }
      return acc;
    }, [])
    .join(', ');

  // return size name, crust name
  return getSizeCrust(product.selectedOptions, [SIZE_SUBTYPE, CRUST_SUBTYPE]);
};

export default useGetSizeCrustDetails;
